@import "abstracts/_abstracts";
.FooterNav {
	margin-bottom: var(--spaceMd);

	&:last-child {
		margin-bottom: 0;
	}

	&-title {
		display: none;
	}

	&-list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 1rem 2rem;
	}

	&-item {
		position: relative;

		a {
			color: inherit;
			text-decoration: none;

			@include on-event {
				text-decoration: underline;
			}
		}
	}
}